<script setup>

</script>

<template>
  <svg class="app-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="arrow-down-to-arc">
      <g id="Vector">
        <path d="M10 2.5V12.5M10 12.5L13.3333 9.16667M10 12.5L6.66667 9.16667M2.5 10C2.5 11.9891 3.29018 13.8968 4.6967 15.3033C6.10322 16.7098 8.01088 17.5 10 17.5C11.9891 17.5 13.8968 16.7098 15.3033 15.3033C16.7098 13.8968 17.5 11.9891 17.5 10" stroke="#4B465C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 2.5V12.5M10 12.5L13.3333 9.16667M10 12.5L6.66667 9.16667M2.5 10C2.5 11.9891 3.29018 13.8968 4.6967 15.3033C6.10322 16.7098 8.01088 17.5 10 17.5C11.9891 17.5 13.8968 16.7098 15.3033 15.3033C16.7098 13.8968 17.5 11.9891 17.5 10" stroke="white" stroke-opacity="0.2" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>