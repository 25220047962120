<script setup>

</script>

<template>
  <svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="paperclip">
      <g id="Vector">
        <path d="M13.75 6.41643L7.79164 12.3748C7.42697 12.7394 7.22209 13.234 7.22209 13.7498C7.22209 14.2655 7.42697 14.7601 7.79164 15.1248C8.15631 15.4894 8.65091 15.6943 9.16664 15.6943C9.68236 15.6943 10.177 15.4894 10.5416 15.1248L16.5 9.16643C17.2293 8.43709 17.6391 7.44788 17.6391 6.41643C17.6391 5.38498 17.2293 4.39578 16.5 3.66643C15.7706 2.93709 14.7814 2.52734 13.75 2.52734C12.7185 2.52734 11.7293 2.93709 11 3.66643L5.04164 9.62476C3.94762 10.7188 3.33301 12.2026 3.33301 13.7498C3.33301 15.2969 3.94762 16.7807 5.04164 17.8748C6.13566 18.9688 7.61946 19.5834 9.16664 19.5834C10.7138 19.5834 12.1976 18.9688 13.2916 17.8748L19.25 11.9164" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.75 6.41643L7.79164 12.3748C7.42697 12.7394 7.22209 13.234 7.22209 13.7498C7.22209 14.2655 7.42697 14.7601 7.79164 15.1248C8.15631 15.4894 8.65091 15.6943 9.16664 15.6943C9.68236 15.6943 10.177 15.4894 10.5416 15.1248L16.5 9.16643C17.2293 8.43709 17.6391 7.44788 17.6391 6.41643C17.6391 5.38498 17.2293 4.39578 16.5 3.66643C15.7706 2.93709 14.7814 2.52734 13.75 2.52734C12.7185 2.52734 11.7293 2.93709 11 3.66643L5.04164 9.62476C3.94762 10.7188 3.33301 12.2026 3.33301 13.7498C3.33301 15.2969 3.94762 16.7807 5.04164 17.8748C6.13566 18.9688 7.61946 19.5834 9.16664 19.5834C10.7138 19.5834 12.1976 18.9688 13.2916 17.8748L19.25 11.9164" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>