<script setup>

</script>

<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="player-pause">
      <g id="Rectangle">
        <rect x="6.5" y="5" width="4" height="14" rx="1" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="6.5" y="5" width="4" height="14" rx="1" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Rectangle_2">
        <rect x="14.5" y="5" width="4" height="14" rx="1" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="14.5" y="5" width="4" height="14" rx="1" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>