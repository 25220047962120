<script setup>

</script>

<template>
  <svg class="app-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="clipboard-text">
      <g id="Vector">
        <path d="M7.50033 4.16667H5.83366C5.39163 4.16667 4.96771 4.34226 4.65515 4.65482C4.34259 4.96738 4.16699 5.39131 4.16699 5.83333V15.8333C4.16699 16.2754 4.34259 16.6993 4.65515 17.0118C4.96771 17.3244 5.39163 17.5 5.83366 17.5H14.167C14.609 17.5 15.0329 17.3244 15.3455 17.0118C15.6581 16.6993 15.8337 16.2754 15.8337 15.8333V5.83333C15.8337 5.39131 15.6581 4.96738 15.3455 4.65482C15.0329 4.34226 14.609 4.16667 14.167 4.16667H12.5003M7.50033 4.16667C7.50033 3.72464 7.67592 3.30072 7.98848 2.98816C8.30104 2.67559 8.72496 2.5 9.16699 2.5H10.8337C11.2757 2.5 11.6996 2.67559 12.0122 2.98816C12.3247 3.30072 12.5003 3.72464 12.5003 4.16667M7.50033 4.16667C7.50033 4.60869 7.67592 5.03262 7.98848 5.34518C8.30104 5.65774 8.72496 5.83333 9.16699 5.83333H10.8337C11.2757 5.83333 11.6996 5.65774 12.0122 5.34518C12.3247 5.03262 12.5003 4.60869 12.5003 4.16667M7.50033 10H12.5003M7.50033 13.3333H12.5003" stroke="#4B465C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.50033 4.16667H5.83366C5.39163 4.16667 4.96771 4.34226 4.65515 4.65482C4.34259 4.96738 4.16699 5.39131 4.16699 5.83333V15.8333C4.16699 16.2754 4.34259 16.6993 4.65515 17.0118C4.96771 17.3244 5.39163 17.5 5.83366 17.5H14.167C14.609 17.5 15.0329 17.3244 15.3455 17.0118C15.6581 16.6993 15.8337 16.2754 15.8337 15.8333V5.83333C15.8337 5.39131 15.6581 4.96738 15.3455 4.65482C15.0329 4.34226 14.609 4.16667 14.167 4.16667H12.5003M7.50033 4.16667C7.50033 3.72464 7.67592 3.30072 7.98848 2.98816C8.30104 2.67559 8.72496 2.5 9.16699 2.5H10.8337C11.2757 2.5 11.6996 2.67559 12.0122 2.98816C12.3247 3.30072 12.5003 3.72464 12.5003 4.16667M7.50033 4.16667C7.50033 4.60869 7.67592 5.03262 7.98848 5.34518C8.30104 5.65774 8.72496 5.83333 9.16699 5.83333H10.8337C11.2757 5.83333 11.6996 5.65774 12.0122 5.34518C12.3247 5.03262 12.5003 4.60869 12.5003 4.16667M7.50033 10H12.5003M7.50033 13.3333H12.5003" stroke="white" stroke-opacity="0.2" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>