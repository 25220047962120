<script setup>

</script>

<template>
  <svg class="app-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="dots-vertical">
      <g id="Vector">
        <path d="M9.1665 9.99967C9.1665 10.2207 9.2543 10.4326 9.41058 10.5889C9.56686 10.7452 9.77882 10.833 9.99984 10.833C10.2209 10.833 10.4328 10.7452 10.5891 10.5889C10.7454 10.4326 10.8332 10.2207 10.8332 9.99967C10.8332 9.77866 10.7454 9.5667 10.5891 9.41042C10.4328 9.25414 10.2209 9.16634 9.99984 9.16634C9.77882 9.16634 9.56686 9.25414 9.41058 9.41042C9.2543 9.5667 9.1665 9.77866 9.1665 9.99967Z" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.1665 15.833C9.1665 16.054 9.2543 16.266 9.41058 16.4223C9.56686 16.5785 9.77882 16.6663 9.99984 16.6663C10.2209 16.6663 10.4328 16.5785 10.5891 16.4223C10.7454 16.266 10.8332 16.054 10.8332 15.833C10.8332 15.612 10.7454 15.4 10.5891 15.2438C10.4328 15.0875 10.2209 14.9997 9.99984 14.9997C9.77882 14.9997 9.56686 15.0875 9.41058 15.2438C9.2543 15.4 9.1665 15.612 9.1665 15.833Z" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.1665 4.16634C9.1665 4.38735 9.2543 4.59932 9.41058 4.7556C9.56686 4.91188 9.77882 4.99967 9.99984 4.99967C10.2209 4.99967 10.4328 4.91188 10.5891 4.7556C10.7454 4.59932 10.8332 4.38735 10.8332 4.16634C10.8332 3.94533 10.7454 3.73337 10.5891 3.57709C10.4328 3.42081 10.2209 3.33301 9.99984 3.33301C9.77882 3.33301 9.56686 3.42081 9.41058 3.57709C9.2543 3.73337 9.1665 3.94533 9.1665 4.16634Z" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>

</template>

<style scoped>

</style>