<script>
  import CoinIcon from "@/components/icons/CoinIcon.vue";

  export default {
    components: {
      CoinIcon,
    },

    props: {
      balance: String
    }
  }
</script>

<template>
  <CoinIcon />
  <span class="balance-text">{{balance}} <span class="rouble-symbol-big">₽</span></span>
</template>

<style scoped>
  .balance-text {
    font-size: 18px;
  }
</style>