<script setup>

</script>

<template>
  <div class="icon-container">
    <svg class="file-icon" width="69" height="68" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="file-description" clip-path="url(#clip0_7307_30034)">
        <g id="Vector">
          <path d="M44.5573 15.0349L42.1702 23.9433C42.012 24.534 42.0948 25.1634 42.4006 25.6929C42.7063 26.2225 43.2099 26.6089 43.8006 26.7672L52.7091 29.1542M44.5573 15.0349L28.9674 10.8576C27.7861 10.541 26.5274 10.7067 25.4682 11.3182C24.4091 11.9298 23.6362 12.937 23.3197 14.1183L14.9651 45.298C14.6486 46.4793 14.8143 47.738 15.4258 48.7972C16.0373 49.8563 17.0445 50.6292 18.2258 50.9457L40.497 56.9133C41.6784 57.2298 42.9371 57.0641 43.9962 56.4526C45.0554 55.8411 45.8282 54.8339 46.1448 53.6525L52.7091 29.1542M44.5573 15.0349L52.7091 29.1542M25.0671 43.2308L38.4298 46.8113M27.4541 34.3223L40.8168 37.9028" stroke="#4B465C" stroke-width="3.45853" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M44.5573 15.0349L42.1702 23.9433C42.012 24.534 42.0948 25.1634 42.4006 25.6929C42.7063 26.2225 43.2099 26.6089 43.8006 26.7672L52.7091 29.1542M44.5573 15.0349L28.9674 10.8576C27.7861 10.541 26.5274 10.7067 25.4682 11.3182C24.4091 11.9298 23.6362 12.937 23.3197 14.1183L14.9651 45.298C14.6486 46.4793 14.8143 47.738 15.4258 48.7972C16.0373 49.8563 17.0445 50.6292 18.2258 50.9457L40.497 56.9133C41.6784 57.2298 42.9371 57.0641 43.9962 56.4526C45.0554 55.8411 45.8282 54.8339 46.1448 53.6525L52.7091 29.1542M44.5573 15.0349L52.7091 29.1542M25.0671 43.2308L38.4298 46.8113M27.4541 34.3223L40.8168 37.9028" stroke="none" stroke-opacity="0.2" stroke-width="3.45853" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7307_30034">
          <rect width="55.3364" height="55.3364" fill="none" transform="translate(15.168) rotate(15)"/>
        </clipPath>
      </defs>
    </svg>

    <svg class="image-icon" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="photo" clip-path="url(#clip0_7307_30036)">
        <g id="Vector">
          <path class="image-icon-part" d="M38.3625 23.412L38.3848 23.4061L38.3625 23.412Z" fill="none"/>
          <path class="image-icon-part" d="M10.4436 26.1189C9.96878 24.3469 10.2173 22.4588 11.1346 20.8701C12.0519 19.2813 13.5627 18.122 15.3347 17.6472L42.0601 10.4862C43.8321 10.0114 45.7202 10.2599 47.3089 11.1772C48.8976 12.0944 50.0569 13.6053 50.5317 15.3773L57.6928 42.1027C58.1676 43.8747 57.919 45.7628 57.0018 47.3515C56.0845 48.9402 54.5737 50.0995 52.8017 50.5743L26.0763 57.7354C24.3043 58.2102 22.4162 57.9616 20.8275 57.0444C19.2387 56.1271 18.0794 54.6163 17.6046 52.8443L10.4436 26.1189Z" fill="none"/>
          <path class="image-icon-part" d="M16.4111 48.3899L24.5629 34.2706C26.0968 31.728 28.6446 31.0453 31.2443 32.4803L45.3637 40.6321" fill="none"/>
          <path class="image-icon-part" d="M39.7159 37.3714L41.3463 34.5475C42.8801 32.0049 45.428 31.3222 48.0276 32.7573L56.4993 37.6484" fill="none"/>
          <path d="M38.3625 23.412L38.3848 23.4061M16.4111 48.3899L24.5629 34.2706C26.0968 31.728 28.6446 31.0453 31.2443 32.4803L45.3637 40.6321M39.7159 37.3714L41.3463 34.5475C42.8801 32.0049 45.428 31.3222 48.0276 32.7573L56.4993 37.6484M10.4436 26.1189C9.96878 24.3469 10.2173 22.4588 11.1346 20.8701C12.0519 19.2813 13.5627 18.122 15.3347 17.6472L42.0601 10.4862C43.8321 10.0114 45.7202 10.2599 47.3089 11.1772C48.8976 12.0944 50.0569 13.6053 50.5317 15.3773L57.6928 42.1027C58.1676 43.8747 57.919 45.7628 57.0018 47.3515C56.0845 48.9402 54.5737 50.0995 52.8017 50.5743L26.0763 57.7354C24.3043 58.2102 22.4162 57.9616 20.8275 57.0444C19.2387 56.1271 18.0794 54.6163 17.6046 52.8443L10.4436 26.1189Z" stroke="#4B465C" stroke-width="3.45853" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M38.3625 23.412L38.3848 23.4061M16.4111 48.3899L24.5629 34.2706C26.0968 31.728 28.6446 31.0453 31.2443 32.4803L45.3637 40.6321M39.7159 37.3714L41.3463 34.5475C42.8801 32.0049 45.428 31.3222 48.0276 32.7573L56.4993 37.6484M10.4436 26.1189C9.96878 24.3469 10.2173 22.4588 11.1346 20.8701C12.0519 19.2813 13.5627 18.122 15.3347 17.6472L42.0601 10.4862C43.8321 10.0114 45.7202 10.2599 47.3089 11.1772C48.8976 12.0944 50.0569 13.6053 50.5317 15.3773L57.6928 42.1027C58.1676 43.8747 57.919 45.7628 57.0018 47.3515C56.0845 48.9402 54.5737 50.0995 52.8017 50.5743L26.0763 57.7354C24.3043 58.2102 22.4162 57.9616 20.8275 57.0444C19.2387 56.1271 18.0794 54.6163 17.6046 52.8443L10.4436 26.1189Z" stroke="none" stroke-opacity="0.2" stroke-width="3.45853" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7307_30036">
          <rect width="55.3364" height="55.3364" fill="none" transform="translate(0.181641 14.5469) rotate(-15)"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style scoped>
.icon-container {
  position: relative;
  height: 70px;
}

.file-icon {
  position: absolute;
  left: calc(50% - 20px);
  z-index: 1;
}

.image-icon {
  position: absolute;
  left: calc(50% - 50px);
  z-index: 2;
}

.file-icon path,
.image-icon path {
  stroke: var(--main-text-color);
}

.image-icon .image-icon-part {
  fill: var(--block-bg-color);
}
</style>
