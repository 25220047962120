<script setup>

</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="check">
      <path id="Path" d="M4.16666 10.0002L8.33332 14.1668L16.6667 5.8335" stroke="#30B271" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<style scoped>

</style>