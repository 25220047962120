<script setup>

</script>

<template>
  <svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="crop-1-1">
      <g id="Vector">
        <path d="M3.66699 5.50008C3.66699 5.01385 3.86015 4.54754 4.20396 4.20372C4.54778 3.8599 5.0141 3.66675 5.50033 3.66675H16.5003C16.9866 3.66675 17.4529 3.8599 17.7967 4.20372C18.1405 4.54754 18.3337 5.01385 18.3337 5.50008V16.5001C18.3337 16.9863 18.1405 17.4526 17.7967 17.7964C17.4529 18.1403 16.9866 18.3334 16.5003 18.3334H5.50033C5.0141 18.3334 4.54778 18.1403 4.20396 17.7964C3.86015 17.4526 3.66699 16.9863 3.66699 16.5001V5.50008Z" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.66699 5.50008C3.66699 5.01385 3.86015 4.54754 4.20396 4.20372C4.54778 3.8599 5.0141 3.66675 5.50033 3.66675H16.5003C16.9866 3.66675 17.4529 3.8599 17.7967 4.20372C18.1405 4.54754 18.3337 5.01385 18.3337 5.50008V16.5001C18.3337 16.9863 18.1405 17.4526 17.7967 17.7964C17.4529 18.1403 16.9866 18.3334 16.5003 18.3334H5.50033C5.0141 18.3334 4.54778 18.1403 4.20396 17.7964C3.86015 17.4526 3.66699 16.9863 3.66699 16.5001V5.50008Z" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>