<template>
  <Modal id="payModal" modal-name="" :is-footer-shown="false">
    <template #body>
      <div class="d-flex flex-wrap justify-content-center py-2 px-5">
        <div class="mb-3">
          <span class="fs-3">Пополнить баланс</span>
        </div>
        <div class="mb-3">
          <span>Впишите сумму для пополнения</span>
        </div>

        <form class="w-100">
          <div class="mb-4">
            <label for="pay" class="form-label mb-0">Сумма</label>
            <input type="number" class="form-control" :class="{'is-invalid': errors.message }" id="pay" v-model="amount">
            <div class="invalid-feedback" id="errorFeedback">{{ errors.message }}</div>
          </div>
        </form>

        <div class="d-flex flex-nowrap gap-3 mt-2">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
          <button type="button" class="btn btn-primary" @click="pay">Пополнить</button>
        </div>
      </div>
    </template>
  </Modal>

  <div>
    <button class="btn btn-primary" data-bs-target="#payModal" data-bs-toggle="modal">Пополнить</button>
  </div>
</template>

<script>
import {Api} from "@/helpers/Api";
import Modal from "@/components/reusable/Modal.vue";

export default {
  components: {Modal},
  data() {
    return {
      amount: 10,
      errors: {},
    }
  },

  props: {
    api: Api,
  },

  methods: {
    async pay() {
      this.errors.message = null;
      const response = await this.api.pay({amount: this.amount});
      if (await response.data) {
        await window.open(response.data.payment_url, '_self');
      } else if (await response.error) {
        this.errors.message = await response.error;
      }
    },
  }
}
</script>

<style scoped>

</style>