<script setup>

</script>

<template>
  <svg class="app-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="square-chevron-left">
      <g id="Vector">
        <path d="M9.16667 7.5L11.6667 10L9.16667 12.5M17.5 15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5L4.16667 17.5C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.6756 16.6993 2.5 16.2754 2.5 15.8333L2.5 4.16667C2.5 3.72464 2.6756 3.30072 2.98816 2.98815C3.30072 2.67559 3.72464 2.5 4.16667 2.5L15.8333 2.5C16.2754 2.5 16.6993 2.6756 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667L17.5 15.8333Z" stroke="#4B465C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.16667 7.5L11.6667 10L9.16667 12.5M17.5 15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5L4.16667 17.5C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.6756 16.6993 2.5 16.2754 2.5 15.8333L2.5 4.16667C2.5 3.72464 2.6756 3.30072 2.98816 2.98815C3.30072 2.67559 3.72464 2.5 4.16667 2.5L15.8333 2.5C16.2754 2.5 16.6993 2.6756 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667L17.5 15.8333Z" stroke="white" stroke-opacity="0.2" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>