<script setup>

</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="photo">
      <g id="Vector">
        <path d="M12.5 6.66667H12.5083M2.5 13.3333L6.66667 9.16663C7.44 8.42246 8.39333 8.42246 9.16667 9.16663L13.3333 13.3333M11.6667 11.6666L12.5 10.8333C13.2733 10.0891 14.2267 10.0891 15 10.8333L17.5 13.3333M2.5 5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H15C15.663 2.5 16.2989 2.76339 16.7678 3.23223C17.2366 3.70107 17.5 4.33696 17.5 5V15C17.5 15.663 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.663 17.5 15 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V5Z" stroke="#4B465C" stroke-width="1.36364" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.5 6.66667H12.5083M2.5 13.3333L6.66667 9.16663C7.44 8.42246 8.39333 8.42246 9.16667 9.16663L13.3333 13.3333M11.6667 11.6666L12.5 10.8333C13.2733 10.0891 14.2267 10.0891 15 10.8333L17.5 13.3333M2.5 5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H15C15.663 2.5 16.2989 2.76339 16.7678 3.23223C17.2366 3.70107 17.5 4.33696 17.5 5V15C17.5 15.663 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.663 17.5 15 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V5Z" stroke="white" stroke-opacity="0.2" stroke-width="1.36364" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>