<script setup>

</script>

<template>
  <svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="database">
      <path id="Vector" d="M3.66699 5.5C3.66699 6.22935 4.43961 6.92882 5.81488 7.44454C7.19014 7.96027 9.0554 8.25 11.0003 8.25C12.9452 8.25 14.8105 7.96027 16.1858 7.44454C17.561 6.92882 18.3337 6.22935 18.3337 5.5M3.66699 5.5C3.66699 4.77065 4.43961 4.07118 5.81488 3.55546C7.19014 3.03973 9.0554 2.75 11.0003 2.75C12.9452 2.75 14.8105 3.03973 16.1858 3.55546C17.561 4.07118 18.3337 4.77065 18.3337 5.5M3.66699 5.5V11M18.3337 5.5V11M3.66699 11C3.66699 11.7293 4.43961 12.4288 5.81488 12.9445C7.19014 13.4603 9.0554 13.75 11.0003 13.75C12.9452 13.75 14.8105 13.4603 16.1858 12.9445C17.561 12.4288 18.3337 11.7293 18.3337 11M3.66699 11V16.5C3.66699 17.2293 4.43961 17.9288 5.81488 18.4445C7.19014 18.9603 9.0554 19.25 11.0003 19.25C12.9452 19.25 14.8105 18.9603 16.1858 18.4445C17.561 17.9288 18.3337 17.2293 18.3337 16.5V11" stroke="#30B271" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<style scoped>

</style>