<script setup>

</script>

<template>
  <svg class="app-icon" width="8" height="8" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="Ellipse 4" cx="4" cy="4" r="4" fill="#30B271"/>
  </svg>
</template>

<style scoped>

</style>