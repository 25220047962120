<script>
  export default {
    props: {
      size: String,
      active: Boolean,
    },

    data() {
      return {
        colors: {
          active: '#1e7a4f',
          default: '#4B465C',
        },
        fill_colors: {
          active: '#30B271',
          default: 'none',
        }
      }
    },
  }
</script>

<template>
  <svg class="app-icon" width="20" height="20" viewBox="0 0 20 20" :fill="active ? fill_colors.active : fill_colors.default" xmlns="http://www.w3.org/2000/svg">
    <g id="thumb-up">
      <g id="Vector">
        <path d="M5.83333 9.16634V15.833C5.83333 16.054 5.74554 16.266 5.58926 16.4223C5.43298 16.5785 5.22101 16.6663 5 16.6663H3.33333C3.11232 16.6663 2.90036 16.5785 2.74408 16.4223C2.5878 16.266 2.5 16.054 2.5 15.833V9.99967C2.5 9.77866 2.5878 9.5667 2.74408 9.41042C2.90036 9.25414 3.11232 9.16634 3.33333 9.16634H5.83333ZM5.83333 9.16634C6.71739 9.16634 7.56523 8.81515 8.19036 8.19003C8.81548 7.56491 9.16667 6.71706 9.16667 5.83301V4.99967C9.16667 4.55765 9.34226 4.13372 9.65482 3.82116C9.96738 3.5086 10.3913 3.33301 10.8333 3.33301C11.2754 3.33301 11.6993 3.5086 12.0118 3.82116C12.3244 4.13372 12.5 4.55765 12.5 4.99967V9.16634H15C15.442 9.16634 15.866 9.34194 16.1785 9.6545C16.4911 9.96706 16.6667 10.391 16.6667 10.833L15.8333 14.9997C15.7135 15.5109 15.4861 15.9499 15.1855 16.2505C14.8849 16.5511 14.5274 16.697 14.1667 16.6663H8.33333C7.67029 16.6663 7.03441 16.4029 6.56557 15.9341C6.09673 15.4653 5.83333 14.8294 5.83333 14.1663" :stroke="active ? colors.active : colors.default" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.83333 9.16634V15.833C5.83333 16.054 5.74554 16.266 5.58926 16.4223C5.43298 16.5785 5.22101 16.6663 5 16.6663H3.33333C3.11232 16.6663 2.90036 16.5785 2.74408 16.4223C2.5878 16.266 2.5 16.054 2.5 15.833V9.99967C2.5 9.77866 2.5878 9.5667 2.74408 9.41042C2.90036 9.25414 3.11232 9.16634 3.33333 9.16634H5.83333ZM5.83333 9.16634C6.71739 9.16634 7.56523 8.81515 8.19036 8.19003C8.81548 7.56491 9.16667 6.71706 9.16667 5.83301V4.99967C9.16667 4.55765 9.34226 4.13372 9.65482 3.82116C9.96738 3.5086 10.3913 3.33301 10.8333 3.33301C11.2754 3.33301 11.6993 3.5086 12.0118 3.82116C12.3244 4.13372 12.5 4.55765 12.5 4.99967V9.16634H15C15.442 9.16634 15.866 9.34194 16.1785 9.6545C16.4911 9.96706 16.6667 10.391 16.6667 10.833L15.8333 14.9997C15.7135 15.5109 15.4861 15.9499 15.1855 16.2505C14.8849 16.5511 14.5274 16.697 14.1667 16.6663H8.33333C7.67029 16.6663 7.03441 16.4029 6.56557 15.9341C6.09673 15.4653 5.83333 14.8294 5.83333 14.1663" stroke="white" stroke-opacity="0.2" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>