<template>
  <div class="ai-title d-flex flex-nowrap align-items-center my-2">
    <span class="me-2" v-if="ai_logo">
      <img :class="size_class" :src="ai_logo.original_url" :alt="ai_logo.file_name">
    </span>
    <div>
      <div class="fs-5">{{ai_name}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      ai_name: String,
      ai_logo: Object,
      size_class: String,
    },
  }
</script>

<style scoped>
  .ai-title {
    height: 30px;
    color: var(--main-header-color);
  }
</style>