<script setup>

</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="music">
      <g id="Vector">
        <path d="M7.5 14.1683C7.5 14.8313 7.23661 15.4672 6.76777 15.9361C6.29893 16.4049 5.66304 16.6683 5 16.6683C4.33696 16.6683 3.70107 16.4049 3.23223 15.9361C2.76339 15.4672 2.5 14.8313 2.5 14.1683C2.5 13.5053 2.76339 12.8694 3.23223 12.4005C3.70107 11.9317 4.33696 11.6683 5 11.6683C5.66304 11.6683 6.29893 11.9317 6.76777 12.4005C7.23661 12.8694 7.5 13.5053 7.5 14.1683ZM7.5 14.1683V3.33496H15.8333V14.1683M15.8333 14.1683C15.8333 14.8313 15.5699 15.4672 15.1011 15.9361C14.6323 16.4049 13.9964 16.6683 13.3333 16.6683C12.6703 16.6683 12.0344 16.4049 11.5656 15.9361C11.0967 15.4672 10.8333 14.8313 10.8333 14.1683C10.8333 13.5053 11.0967 12.8694 11.5656 12.4005C12.0344 11.9317 12.6703 11.6683 13.3333 11.6683C13.9964 11.6683 14.6323 11.9317 15.1011 12.4005C15.5699 12.8694 15.8333 13.5053 15.8333 14.1683ZM7.5 6.66829H15.8333" stroke="#4B465C" stroke-width="1.36364" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.5 14.1683C7.5 14.8313 7.23661 15.4672 6.76777 15.9361C6.29893 16.4049 5.66304 16.6683 5 16.6683C4.33696 16.6683 3.70107 16.4049 3.23223 15.9361C2.76339 15.4672 2.5 14.8313 2.5 14.1683C2.5 13.5053 2.76339 12.8694 3.23223 12.4005C3.70107 11.9317 4.33696 11.6683 5 11.6683C5.66304 11.6683 6.29893 11.9317 6.76777 12.4005C7.23661 12.8694 7.5 13.5053 7.5 14.1683ZM7.5 14.1683V3.33496H15.8333V14.1683M15.8333 14.1683C15.8333 14.8313 15.5699 15.4672 15.1011 15.9361C14.6323 16.4049 13.9964 16.6683 13.3333 16.6683C12.6703 16.6683 12.0344 16.4049 11.5656 15.9361C11.0967 15.4672 10.8333 14.8313 10.8333 14.1683C10.8333 13.5053 11.0967 12.8694 11.5656 12.4005C12.0344 11.9317 12.6703 11.6683 13.3333 11.6683C13.9964 11.6683 14.6323 11.9317 15.1011 12.4005C15.5699 12.8694 15.8333 13.5053 15.8333 14.1683ZM7.5 6.66829H15.8333" stroke="white" stroke-opacity="0.2" stroke-width="1.36364" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>