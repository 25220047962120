<script setup>

</script>

<template>
  <svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="logout-2">
      <g id="Vector">
        <path d="M9.16667 7.33317V5.49984C9.16667 5.01361 9.35982 4.54729 9.70364 4.20347C10.0475 3.85966 10.5138 3.6665 11 3.6665H17.4167C17.9029 3.6665 18.3692 3.85966 18.713 4.20347C19.0568 4.54729 19.25 5.01361 19.25 5.49984V16.4998C19.25 16.9861 19.0568 17.4524 18.713 17.7962C18.3692 18.14 17.9029 18.3332 17.4167 18.3332H11C10.5138 18.3332 10.0475 18.14 9.70364 17.7962C9.35982 17.4524 9.16667 16.9861 9.16667 16.4998V14.6665M13.75 10.9998H2.75M2.75 10.9998L5.5 8.24984M2.75 10.9998L5.5 13.7498" stroke="#4B465C" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.16667 7.33317V5.49984C9.16667 5.01361 9.35982 4.54729 9.70364 4.20347C10.0475 3.85966 10.5138 3.6665 11 3.6665H17.4167C17.9029 3.6665 18.3692 3.85966 18.713 4.20347C19.0568 4.54729 19.25 5.01361 19.25 5.49984V16.4998C19.25 16.9861 19.0568 17.4524 18.713 17.7962C18.3692 18.14 17.9029 18.3332 17.4167 18.3332H11C10.5138 18.3332 10.0475 18.14 9.70364 17.7962C9.35982 17.4524 9.16667 16.9861 9.16667 16.4998V14.6665M13.75 10.9998H2.75M2.75 10.9998L5.5 8.24984M2.75 10.9998L5.5 13.7498" stroke="white" stroke-opacity="0.2" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>