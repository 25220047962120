<script setup>

</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="x">
      <path id="Vector" d="M15 5L5 15M5 5L15 15" stroke="#EA5455" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<style scoped>

</style>