<script setup>

</script>

<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5 9C19.1 11.667 16.1 13 12.5 13C8.9 13 5.9 11.667 3.5 9M3.5 15L6 11.2M21.4998 14.976L19.0078 11.2M9.5 17L10 13M15.5 17L15 13" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.5 9C19.1 11.667 16.1 13 12.5 13C8.9 13 5.9 11.667 3.5 9M3.5 15L6 11.2M21.4998 14.976L19.0078 11.2M9.5 17L10 13M15.5 17L15 13" stroke="white" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>