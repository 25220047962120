<script>
  export default {
    props: {
      size: String,
      color: String,
    },

    data() {
      return {
        colors: {
          danger: '#FC5555',
          default: '#4B465C',
        }
      }
    }
  }
</script>

<template>
  <svg class="app-icon-danger" :width="size" :height="size" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="trash">
      <path id="Vector" d="M3.66699 6.41667H18.3337M9.16699 10.0833V15.5833M12.8337 10.0833V15.5833M4.58366 6.41667L5.50033 17.4167C5.50033 17.9029 5.69348 18.3692 6.0373 18.713C6.38111 19.0568 6.84743 19.25 7.33366 19.25H14.667C15.1532 19.25 15.6195 19.0568 15.9634 18.713C16.3072 18.3692 16.5003 17.9029 16.5003 17.4167L17.417 6.41667M8.25033 6.41667V3.66667C8.25033 3.42355 8.3469 3.19039 8.51881 3.01849C8.69072 2.84658 8.92388 2.75 9.16699 2.75H12.8337C13.0768 2.75 13.3099 2.84658 13.4818 3.01849C13.6537 3.19039 13.7503 3.42355 13.7503 3.66667V6.41667" :stroke="colors[color]" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<style scoped>

</style>