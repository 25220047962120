<script>
export default {
  props: {
    size: String,
    active: Boolean,
  },

  data() {
    return {
      colors: {
        active: '#4B465C',
        default: '#4B465C',
      },
      fill_colors: {
        active: '#FC5555',
        default: 'none',
      }
    }
  },
}
</script>

<template>
  <svg class="app-icon" width="20" height="20" viewBox="0 0 20 20" :fill="active ? fill_colors.active : fill_colors.default" xmlns="http://www.w3.org/2000/svg">
    <g id="thumb-down">
      <g id="Vector">
        <path d="M14.1667 10.8331V4.16645C14.1667 3.94543 14.2545 3.73347 14.4107 3.57719C14.567 3.42091 14.779 3.33311 15 3.33311H16.6667C16.8877 3.33311 17.0996 3.42091 17.2559 3.57719C17.4122 3.73347 17.5 3.94543 17.5 4.16645V9.99978C17.5 10.2208 17.4122 10.4328 17.2559 10.589C17.0996 10.7453 16.8877 10.8331 16.6667 10.8331H14.1667ZM14.1667 10.8331C13.2826 10.8331 12.4348 11.1843 11.8096 11.8094C11.1845 12.4345 10.8333 13.2824 10.8333 14.1664V14.9998C10.8333 15.4418 10.6577 15.8657 10.3452 16.1783C10.0326 16.4909 9.60869 16.6664 9.16667 16.6664C8.72464 16.6664 8.30072 16.4909 7.98816 16.1783C7.6756 15.8657 7.5 15.4418 7.5 14.9998V10.8331H5C4.55797 10.8331 4.13405 10.6575 3.82149 10.345C3.50893 10.0324 3.33333 9.60847 3.33333 9.16645L4.16667 4.99978C4.28651 4.48855 4.51386 4.04958 4.81446 3.74898C5.11506 3.44838 5.47264 3.30243 5.83333 3.33311H11.6667C12.3297 3.33311 12.9656 3.59651 13.4344 4.06535C13.9033 4.53419 14.1667 5.17007 14.1667 5.83311" :stroke="active ? colors.active : colors.default" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.1667 10.8331V4.16645C14.1667 3.94543 14.2545 3.73347 14.4107 3.57719C14.567 3.42091 14.779 3.33311 15 3.33311H16.6667C16.8877 3.33311 17.0996 3.42091 17.2559 3.57719C17.4122 3.73347 17.5 3.94543 17.5 4.16645V9.99978C17.5 10.2208 17.4122 10.4328 17.2559 10.589C17.0996 10.7453 16.8877 10.8331 16.6667 10.8331H14.1667ZM14.1667 10.8331C13.2826 10.8331 12.4348 11.1843 11.8096 11.8094C11.1845 12.4345 10.8333 13.2824 10.8333 14.1664V14.9998C10.8333 15.4418 10.6577 15.8657 10.3452 16.1783C10.0326 16.4909 9.60869 16.6664 9.16667 16.6664C8.72464 16.6664 8.30072 16.4909 7.98816 16.1783C7.6756 15.8657 7.5 15.4418 7.5 14.9998V10.8331H5C4.55797 10.8331 4.13405 10.6575 3.82149 10.345C3.50893 10.0324 3.33333 9.60847 3.33333 9.16645L4.16667 4.99978C4.28651 4.48855 4.51386 4.04958 4.81446 3.74898C5.11506 3.44838 5.47264 3.30243 5.83333 3.33311H11.6667C12.3297 3.33311 12.9656 3.59651 13.4344 4.06535C13.9033 4.53419 14.1667 5.17007 14.1667 5.83311" stroke="white" stroke-opacity="0.2" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>