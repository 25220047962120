<script setup>

</script>

<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="player-play">
      <g id="Path">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 4V20L20.5 12L7.5 4Z" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 4V20L20.5 12L7.5 4Z" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>