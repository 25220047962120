<script setup>

</script>

<template>
  <svg class="app-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="coins">
      <g id="Vector">
        <path d="M9.75 15.1667C9.75 16.9618 12.6598 18.4167 16.25 18.4167C19.8402 18.4167 22.75 16.9618 22.75 15.1667M9.75 15.1667C9.75 13.3716 12.6598 11.9167 16.25 11.9167C19.8402 11.9167 22.75 13.3716 22.75 15.1667M9.75 15.1667V19.5C9.75 21.294 12.6598 22.75 16.25 22.75C19.8402 22.75 22.75 21.294 22.75 19.5V15.1667M3.25 6.5C3.25 7.66133 4.48933 8.73383 6.5 9.3145C8.51067 9.89517 10.9893 9.89517 13 9.3145C15.0107 8.73383 16.25 7.66133 16.25 6.5C16.25 5.33867 15.0107 4.26617 13 3.6855C10.9893 3.10483 8.51067 3.10483 6.5 3.6855C4.48933 4.26617 3.25 5.33867 3.25 6.5ZM3.25 6.5V17.3333C3.25 18.2953 4.08633 18.9042 5.41667 19.5M3.25 11.9167C3.25 12.8787 4.08633 13.4875 5.41667 14.0833" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.75 15.1667C9.75 16.9618 12.6598 18.4167 16.25 18.4167C19.8402 18.4167 22.75 16.9618 22.75 15.1667M9.75 15.1667C9.75 13.3716 12.6598 11.9167 16.25 11.9167C19.8402 11.9167 22.75 13.3716 22.75 15.1667M9.75 15.1667V19.5C9.75 21.294 12.6598 22.75 16.25 22.75C19.8402 22.75 22.75 21.294 22.75 19.5V15.1667M3.25 6.5C3.25 7.66133 4.48933 8.73383 6.5 9.3145C8.51067 9.89517 10.9893 9.89517 13 9.3145C15.0107 8.73383 16.25 7.66133 16.25 6.5C16.25 5.33867 15.0107 4.26617 13 3.6855C10.9893 3.10483 8.51067 3.10483 6.5 3.6855C4.48933 4.26617 3.25 5.33867 3.25 6.5ZM3.25 6.5V17.3333C3.25 18.2953 4.08633 18.9042 5.41667 19.5M3.25 11.9167C3.25 12.8787 4.08633 13.4875 5.41667 14.0833" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>