<script setup>

</script>

<template>
  <svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="calendar-event">
      <g id="Rectangle">
        <rect x="3.66666" y="4.58301" width="14.6667" height="14.6667" rx="2" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="3.66666" y="4.58301" width="14.6667" height="14.6667" rx="2" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Path">
        <path d="M14.6667 2.75V6.41667" stroke="#4B465C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.6667 2.75V6.41667" stroke="white" stroke-opacity="0.2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Path_2">
        <path d="M7.33333 2.75V6.41667" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.33333 2.75V6.41667" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Path_3">
        <path d="M3.66666 10.0833H18.3333" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.66666 10.0833H18.3333" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Rectangle_2">
        <rect x="7.33334" y="13.75" width="1.83333" height="1.83333" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="7.33334" y="13.75" width="1.83333" height="1.83333" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>
 .app-icon rect, path {
   stroke: var(--main-text-color);
 }
</style>