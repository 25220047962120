<template>
  <div class="transaction d-flex flex-column w-100 overflow-y-auto overflow-x-hidden">
    <SectionTitle title="Транзакции" />

    <form class="d-flex flex-nowrap justify-content-between gap-2 mb-4">
      <SelectOptions class="transaction__operation-select" :items="operations"
                     name="Операция"
                     :value="operationType"
                     @update:value="value => operationType = value" />
      <div class="d-flex flex-nowrap gap-2 ms-3">
        <div class="d-flex flex-nowrap">
          <CalendarIcon class="calendar-icon" />
          <Calendar :panel-class="'calendar__' + appThemeClass" v-model="date_from" dateFormat="dd-mm-yy"/>
        </div>
        <div class="calendar-inputs-dash"></div>
        <div class="transaction__calendar-input d-flex flex-nowrap">
          <CalendarIcon class="calendar-icon" />
          <Calendar :panel-class="'calendar__' + appThemeClass" v-model="date_to" dateFormat="dd-mm-yy"/>
        </div>
      </div>
      <button class="btn btn-outline-primary text-nowrap" @click.prevent="getInPeriod">Применить фильтр</button>
    </form>

    <div class="transactions-table__head-container container-fluid">
      <div class="row text-center text-uppercase transactions-table__head">
        <div class="col-2 text-break">Дата и время</div>
        <div class="col-2 text-break">Операция</div>
        <div class="col-3 text-break">Услуга</div>
        <div class="col-2 text-break">Сумма</div>
        <div class="col-3 text-break">Подсчёт</div>
      </div>
    </div>

    <div class="transactions-table__body-container container-fluid overflow-y-scroll">
      <div class="transactions-table__body">
        <div class="row" v-for="transaction in transactions" :key="transaction.id">
          <div class="col-2 d-flex align-items-center text-break" v-text="formatTime(transaction.created_at)"></div>
          <div class="col-2 d-flex align-items-center text-break">{{ transaction.type }}</div>
          <div class="col-3 d-flex align-items-center text-break">{{ transaction.service }}</div>
          <div class="col-2 d-flex align-items-center text-break">
            <div class="d-flex flex-nowrap align-items-center gap-2">
              <RoubleIcon :color="transaction.amount >= 0 ? 'default' : 'danger' "/>
              {{ transaction.amount }}
            </div>
          </div>
          <div class="col-3 align-items-center text-break">
          <span v-for="[key, value] in Object.entries(transaction.calculation)" :key="key">
            {{transactionsParams[key]}}: {{value}}
            <span v-if="transaction.prices[key]">по {{transaction.currency}}{{transaction.prices[key]}} за {{priceToUnits[key]}}</span>
            <span v-if="key === 'rate'"> руб.</span>
            <br>
          </span>
            <span v-if="transaction.prices.response_price">Генерация ответа: 1 ответ по {{transaction.currency}}{{transaction.prices.response_price}}</span>
            <span v-if="transaction.amount > 0">{{transaction.service}}</span>
          </div>
        </div>
      </div>
    </div>


    <div class="transactions-table__buttons">
      <div class="transactions-table__buttons-group me-2">
        <button class="transactions-table__btn btn-secondary small" @click="getPreviousPage"><ArrowLeftIcon /></button>
        <div class="d-flex" v-for="item in shownButtons" :key="item">
          <button v-if="typeof item === 'number'"
                  class="transactions-table__btn small"
                  :class="{
                    'btn-primary': page === item,
                    'btn-secondary': page !== item,
                  }"
                  @click="getPage(item)"
                  v-text="item"></button>
          <span v-else class="align-self-end mx-1" v-text="item"></span>
        </div>
        <button class="transactions-table__btn btn-secondary small" @click="getNextPage"><ArrowRightIcon /></button>
      </div>

      <div class="transactions-table__buttons-group ms-2">
        <button class="transactions-table__btn small"
                v-for="amount in paginationAmounts" :key="amount"
                :class="{
                   'btn-primary': perPage === amount,
                   'btn-secondary': perPage !== amount,
                  }"
                @click="getPerPage(amount)"
                v-text="amount"></button>
      </div>
    </div>
    </div>
</template>

<script>
import {Api} from "@/helpers/Api";
import {formatTime} from "@/helpers/formatters";
import {transactionsParams} from "@/data/transactions";
import {priceToUnits} from "@/data/calculation";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import RoubleIcon from "@/components/icons/RoubleIcon.vue";
import SectionTitle from "@/components/reusable/SectionTitle.vue";
import SelectOptions from "@/components/reusable/SelectOptions.vue";
import Calendar from "primevue/calendar";
import CalendarIcon from "@/components/icons/CalendarIcon.vue";

export default {
  computed: {
    priceToUnits() {
      return priceToUnits
    },

    transactionsParams() {
      return transactionsParams
    },

    shownButtons() {
      const buttons = [];
      const left = Math.max(2, this.page - 1);
      const right = Math.min(this.lastPage - 1, this.page + 1);

      buttons.push(1);

      if (left > 2) {
        buttons.push('...');
      }

      for (let i = left; i <= right; i++) {
        buttons.push(i);
      }
      if (right < this.lastPage - 1) {
        buttons.push('...');
      }

      if (this.lastPage > 1) {
        buttons.push(this.lastPage);
      }

      return buttons;
    },

    appThemeClass() {
      return this.$store.state.appTheme;
    }
  },

  components: {CalendarIcon, SelectOptions, SectionTitle, RoubleIcon, ArrowRightIcon, ArrowLeftIcon, Calendar},

  data() {
    return {
      page: 1,
      transactions: [],
      sortDesc: true,
      lastPage: 1,
      date_from: '',
      date_to: '',
      cancel: 'Отмена',
      select: 'Выбрать',
      paginationAmounts: [5, 10, 50, 100, 150],
      perPage: 10,
      operationType: 'all',
      operations: {
        all: 'Все',
        pay: 'Списание',
        fill: 'Пополнение',
      }
    }
  },

  props: {
    api: Api,
  },



  async beforeMount() {
    const response = await this.api.getTransactions({page: this.page});
    this.transactions = await response.data;
    this.lastPage = await response.meta.last_page;
  },

  methods: {
    async getNextPage() {
      if (this.page + 1 <= this.lastPage) {
        this.page = this.page + 1;
        const response = await this.api.getTransactions({
          page: this.page,
          sort: this.sortDesc,
          per_page: this.perPage,
        });
        this.transactions = await response.data;
      }
    },

    async getPreviousPage() {
      if (this.page - 1 > 0) {
        this.page = this.page - 1;
        const response = await this.api.getTransactions({
          page: this.page,
          sort: this.sortDesc,
          per_page: this.perPage,
          date_from: this.date_from,
          date_to: this.date_to,
          type: this.operationType,
        });
        this.transactions = await response.data;
      }
    },

    async getPage(page) {
      this.page = page;
      const response = await this.api.getTransactions({
        page: this.page,
        sort: this.sortDesc,
        per_page: this.perPage,
        date_from: this.date_from,
        date_to: this.date_to,
        type: this.operationType,
      });
      this.transactions = await response.data;
    },

    async getInPeriod() {
      const response = await this.api.getTransactions({
        page: 1,
        sort: this.sortDesc,
        per_page: this.perPage,
        date_from: this.date_from,
        date_to: this.date_to,
        type: this.operationType,
      });
      this.transactions = await response.data;
      this.lastPage =  await response.meta.last_page;
    },

    async getPerPage(perPage) {
      this.perPage = perPage;
      const response = await this.api.getTransactions({
        page: this.page,
        sort: this.sortDesc,
        per_page: this.perPage,
        date_from: this.date_from,
        date_to: this.date_to,
        type: this.operationType,
      });
      this.transactions = await response.data;
      this.lastPage =  await response.meta.last_page;
    },

    formatTime(time) {
      return formatTime(time, false);
    },
  }
}
</script>

<style scoped>
  .transactions-table__head-container {
    background-color: var(--block-bg-color);
  }

  .transactions-table__head .col-2, .col-2, .col-3 {
    border: var(--border-color) 1px solid;
    padding: 16px;
  }

  .transactions-table__body .col-2, .col-2, .col-3 {
    border: var(--border-color) 1px solid;
    padding: 18px 14px;
  }

  .transactions-table__head {
    font-size: 15px;
    width: calc(100% + 8.5px);
  }

  .transactions-table__body-container {
    background-color: var(--block-bg-color);
  }

  .transactions-table__body {
    font-size: 15px;
  }

  .container-fluid {
    box-sizing: border-box;
  }

  .transactions-table__btn {
    border: none;
    border-radius: 6px;
    min-width: 28px;
    height: 28px;
    color: var(--main-text-color);
  }

  .transactions-table__buttons {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    padding: 24px;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 24px;
    border-radius: 0 0 6px 6px;
    border-top: var(--border-color) 1px solid;
    box-shadow: 0px 2px 4px 0px #a5a3ae4d;
    background-color: var(--block-bg-color);
  }

  .transactions-table__buttons-group {
    display: flex;
    flex-wrap: nowrap;
    gap: 2px;
    align-items: stretch;
  }

  .transaction__operation-select {
    width: 50%;
  }

  .calendar-inputs-dash {
    width: 25px;
    border: 1px rgba(219, 218, 222, 1) solid;
    height: 1px;
    align-self: center;
    position: relative;
    left: 2.5%;
   }

  .calendar-icon {
    position: relative;
    top: 10px;
    left: 35px;
    width: 35px;
  }
</style>