<script>
  export default {
    props: {
      size: String,
      color: String,
    },

    data() {
      return {
        colors: {
          danger: '#FC5555',
          default: '#30B271',
        }
      }
    }
  }
</script>

<template>
  <div class="icon-cirlce-box" :class="{'color-default': color === 'default', 'color-danger' : color === 'danger'}">
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="currency-rubel">
        <path id="Vector" d="M7.33333 17.4163V4.58301H12.8333C13.5627 4.58301 14.2622 4.87274 14.7779 5.38846C15.2936 5.90419 15.5833 6.60366 15.5833 7.33301C15.5833 8.06235 15.2936 8.76183 14.7779 9.27755C14.2622 9.79328 13.5627 10.083 12.8333 10.083H5.5M12.8333 13.7497H5.5" :stroke="colors[color]" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
  </div>
</template>

<style scoped>
  .icon-cirlce-box {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .color-danger {
    background-color: rgba(234, 84, 85, 0.08);
  }

  .color-default {
    background-color: rgba(48, 178, 113, 0.16);
  }
</style>