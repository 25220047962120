<script setup>

</script>

<template>
  <svg class="theme-switch-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="moon">
      <g id="Vector">
        <path d="M13.0001 3.24988C13.1431 3.24988 13.285 3.24988 13.4258 3.24988C12.0341 4.54306 11.1396 6.28304 10.8977 8.16737C10.6557 10.0517 11.0818 11.9612 12.1017 13.564C13.1217 15.1668 14.671 16.3615 16.4804 16.9405C18.2898 17.5194 20.2449 17.446 22.0058 16.733C21.3285 18.3629 20.2214 19.7783 18.8026 20.8283C17.3839 21.8783 15.7067 22.5235 13.9501 22.6951C12.1934 22.8666 10.4231 22.5581 8.82805 21.8024C7.23298 21.0467 5.87296 19.8722 4.89306 18.4042C3.91316 16.9362 3.35014 15.2297 3.26405 13.4668C3.17796 11.7038 3.57204 9.95062 4.40424 8.3941C5.23645 6.83759 6.47556 5.53617 7.98942 4.62867C9.50327 3.72117 11.2351 3.24164 13.0001 3.24121V3.24988Z" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.0001 3.24988C13.1431 3.24988 13.285 3.24988 13.4258 3.24988C12.0341 4.54306 11.1396 6.28304 10.8977 8.16737C10.6557 10.0517 11.0818 11.9612 12.1017 13.564C13.1217 15.1668 14.671 16.3615 16.4804 16.9405C18.2898 17.5194 20.2449 17.446 22.0058 16.733C21.3285 18.3629 20.2214 19.7783 18.8026 20.8283C17.3839 21.8783 15.7067 22.5235 13.9501 22.6951C12.1934 22.8666 10.4231 22.5581 8.82805 21.8024C7.23298 21.0467 5.87296 19.8722 4.89306 18.4042C3.91316 16.9362 3.35014 15.2297 3.26405 13.4668C3.17796 11.7038 3.57204 9.95062 4.40424 8.3941C5.23645 6.83759 6.47556 5.53617 7.98942 4.62867C9.50327 3.72117 11.2351 3.24164 13.0001 3.24121V3.24988Z" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>

</template>

<style scoped>

</style>