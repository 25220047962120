<script setup>

</script>

<template>
  <svg class="app-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="analyze">
      <g id="Vector">
        <path d="M16.6667 9.16711C16.4628 7.70073 15.7825 6.34205 14.7305 5.30032C13.6786 4.2586 12.3133 3.59162 10.845 3.40211C9.53372 3.23147 8.20123 3.44998 7.01313 4.03047C5.82504 4.61096 4.83375 5.52783 4.16249 6.66711M3.33333 10.8338C3.52612 12.2214 4.14586 13.5146 5.10664 14.5342C6.06741 15.5537 7.32161 16.2491 8.69532 16.5238C10.069 16.7986 11.4942 16.6391 12.7732 16.0675C14.0522 15.4959 15.1217 14.5405 15.8333 13.3338M15 13.3338C15 13.5548 15.0878 13.7668 15.2441 13.9231C15.4004 14.0793 15.6123 14.1671 15.8333 14.1671C16.0543 14.1671 16.2663 14.0793 16.4226 13.9231C16.5789 13.7668 16.6667 13.5548 16.6667 13.3338C16.6667 13.1128 16.5789 12.9008 16.4226 12.7445C16.2663 12.5883 16.0543 12.5005 15.8333 12.5005C15.6123 12.5005 15.4004 12.5883 15.2441 12.7445C15.0878 12.9008 15 13.1128 15 13.3338ZM3.33333 6.66713C3.33333 6.88815 3.42113 7.10011 3.57741 7.25639C3.73369 7.41267 3.94565 7.50047 4.16666 7.50047C4.38768 7.50047 4.59964 7.41267 4.75592 7.25639C4.9122 7.10011 4.99999 6.88815 4.99999 6.66713C4.99999 6.44612 4.9122 6.23416 4.75592 6.07788C4.59964 5.9216 4.38768 5.8338 4.16666 5.8338C3.94565 5.8338 3.73369 5.9216 3.57741 6.07788C3.42113 6.23416 3.33333 6.44612 3.33333 6.66713ZM7.49999 10.0005C7.49999 10.6635 7.76339 11.2994 8.23223 11.7682C8.70107 12.2371 9.33695 12.5005 9.99999 12.5005C10.663 12.5005 11.2989 12.2371 11.7678 11.7682C12.2366 11.2994 12.5 10.6635 12.5 10.0005C12.5 9.33743 12.2366 8.70154 11.7678 8.2327C11.2989 7.76386 10.663 7.50047 9.99999 7.50047C9.33695 7.50047 8.70107 7.76386 8.23223 8.2327C7.76339 8.70154 7.49999 9.33743 7.49999 10.0005Z" stroke="#4B465C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.6667 9.16711C16.4628 7.70073 15.7825 6.34205 14.7305 5.30032C13.6786 4.2586 12.3133 3.59162 10.845 3.40211C9.53372 3.23147 8.20123 3.44998 7.01313 4.03047C5.82504 4.61096 4.83375 5.52783 4.16249 6.66711M3.33333 10.8338C3.52612 12.2214 4.14586 13.5146 5.10664 14.5342C6.06741 15.5537 7.32161 16.2491 8.69532 16.5238C10.069 16.7986 11.4942 16.6391 12.7732 16.0675C14.0522 15.4959 15.1217 14.5405 15.8333 13.3338M15 13.3338C15 13.5548 15.0878 13.7668 15.2441 13.9231C15.4004 14.0793 15.6123 14.1671 15.8333 14.1671C16.0543 14.1671 16.2663 14.0793 16.4226 13.9231C16.5789 13.7668 16.6667 13.5548 16.6667 13.3338C16.6667 13.1128 16.5789 12.9008 16.4226 12.7445C16.2663 12.5883 16.0543 12.5005 15.8333 12.5005C15.6123 12.5005 15.4004 12.5883 15.2441 12.7445C15.0878 12.9008 15 13.1128 15 13.3338ZM3.33333 6.66713C3.33333 6.88815 3.42113 7.10011 3.57741 7.25639C3.73369 7.41267 3.94565 7.50047 4.16666 7.50047C4.38768 7.50047 4.59964 7.41267 4.75592 7.25639C4.9122 7.10011 4.99999 6.88815 4.99999 6.66713C4.99999 6.44612 4.9122 6.23416 4.75592 6.07788C4.59964 5.9216 4.38768 5.8338 4.16666 5.8338C3.94565 5.8338 3.73369 5.9216 3.57741 6.07788C3.42113 6.23416 3.33333 6.44612 3.33333 6.66713ZM7.49999 10.0005C7.49999 10.6635 7.76339 11.2994 8.23223 11.7682C8.70107 12.2371 9.33695 12.5005 9.99999 12.5005C10.663 12.5005 11.2989 12.2371 11.7678 11.7682C12.2366 11.2994 12.5 10.6635 12.5 10.0005C12.5 9.33743 12.2366 8.70154 11.7678 8.2327C11.2989 7.76386 10.663 7.50047 9.99999 7.50047C9.33695 7.50047 8.70107 7.76386 8.23223 8.2327C7.76339 8.70154 7.49999 9.33743 7.49999 10.0005Z" stroke="white" stroke-opacity="0.2" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>

</template>

<style scoped>

</style>