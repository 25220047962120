<script>
export default {
  props: {
    size: String
  },
}
</script>

<template>
  <svg class="app-icon" :width="size" :height="size" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="alert-circle">
      <g id="Oval">
        <circle cx="7" cy="7" r="5.25" stroke="#4B465C" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="7" cy="7" r="5.25" stroke="white" stroke-opacity="0.2" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Path">
        <path d="M7.00016 4.66699V7.00033" stroke="#4B465C" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.00016 4.66699V7.00033" stroke="white" stroke-opacity="0.2" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Path_2">
        <path d="M6.99969 9.33366H7.00552" stroke="#4B465C" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.99969 9.33366H7.00552" stroke="white" stroke-opacity="0.2" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>