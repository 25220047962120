<script>
  export default {
    props: {
      size: String,
      active: Boolean,
    },

    data() {
      return {
        colors: {
          active: '#1e7a4f',
          default: '#4B465C',
        },
        fill_colors: {
          active: '#30B271',
          default: 'none',
        }
      }
    },
  }
</script>

<template>
  <svg class="app-icon" :width="size" :height="size" viewBox="0 0 22 22" :fill="active ? fill_colors.active : fill_colors.default" xmlns="http://www.w3.org/2000/svg">
    <g id="bookmark">
      <path id="Vector" d="M16.5 6.41667V19.25L11 15.5833L5.5 19.25V6.41667C5.5 5.44421 5.88631 4.51158 6.57394 3.82394C7.26158 3.13631 8.19421 2.75 9.16667 2.75H12.8333C13.8058 2.75 14.7384 3.13631 15.4261 3.82394C16.1137 4.51158 16.5 5.44421 16.5 6.41667Z" :stroke="active ? colors.active : colors.default" stroke-opacity="0.9" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>

</template>

<style scoped>

</style>