<script setup>

</script>

<template>
  <svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="edit">
      <g id="Vector">
        <path d="M6.4165 6.41678H5.49984C5.01361 6.41678 4.54729 6.60993 4.20347 6.95375C3.85966 7.29757 3.6665 7.76388 3.6665 8.25011V16.5001C3.6665 16.9863 3.85966 17.4527 4.20347 17.7965C4.54729 18.1403 5.01361 18.3334 5.49984 18.3334H13.7498C14.2361 18.3334 14.7024 18.1403 15.0462 17.7965C15.39 17.4527 15.5832 16.9863 15.5832 16.5001V15.5834M14.6665 4.58345L17.4165 7.33345M18.6861 6.03635C19.0471 5.67532 19.2499 5.18567 19.2499 4.6751C19.2499 4.16453 19.0471 3.67487 18.6861 3.31385C18.3251 2.95282 17.8354 2.75 17.3248 2.75C16.8143 2.75 16.3246 2.95282 15.9636 3.31385L8.24984 11.0001V13.7501H10.9998L18.6861 6.03635Z" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.4165 6.41678H5.49984C5.01361 6.41678 4.54729 6.60993 4.20347 6.95375C3.85966 7.29757 3.6665 7.76388 3.6665 8.25011V16.5001C3.6665 16.9863 3.85966 17.4527 4.20347 17.7965C4.54729 18.1403 5.01361 18.3334 5.49984 18.3334H13.7498C14.2361 18.3334 14.7024 18.1403 15.0462 17.7965C15.39 17.4527 15.5832 16.9863 15.5832 16.5001V15.5834M14.6665 4.58345L17.4165 7.33345M18.6861 6.03635C19.0471 5.67532 19.2499 5.18567 19.2499 4.6751C19.2499 4.16453 19.0471 3.67487 18.6861 3.31385C18.3251 2.95282 17.8354 2.75 17.3248 2.75C16.8143 2.75 16.3246 2.95282 15.9636 3.31385L8.24984 11.0001V13.7501H10.9998L18.6861 6.03635Z" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>