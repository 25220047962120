<script setup>

</script>

<template>
  <svg class="app-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="plus">
      <path id="Path" d="M9 3.75V14.25" stroke="#30B271" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Path_2" d="M3.75 9H14.25" stroke="#30B271" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<style scoped>

</style>