<template>
  <span class="me-2" v-if="ai_logo">
    <img :class="size_class" :src="ai_logo.original_url" :alt="ai_logo.file_name">
  </span>
</template>

<script>
export default {
  props: {
    ai_logo: Object,
    size_class: String,
  },
}
</script>

<style scoped>

</style>