<script setup>

</script>

<template>
  <svg class="app-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="layers-subtract">
      <g id="Rectangle">
        <rect x="6.66669" y="3.33301" width="10" height="10" rx="1.66667" stroke="#4B465C" stroke-opacity="0.75" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="6.66669" y="3.33301" width="10" height="10" rx="1.66667" stroke="#4B465C" stroke-opacity="0.75" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Path">
        <path d="M13.3333 13.3337V15.0003C13.3333 15.9208 12.5871 16.667 11.6666 16.667H4.99998C4.07951 16.667 3.33331 15.9208 3.33331 15.0003V8.33366C3.33331 7.41318 4.07951 6.66699 4.99998 6.66699H6.66665" stroke="#4B465C" stroke-opacity="0.75" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3333 13.3337V15.0003C13.3333 15.9208 12.5871 16.667 11.6666 16.667H4.99998C4.07951 16.667 3.33331 15.9208 3.33331 15.0003V8.33366C3.33331 7.41318 4.07951 6.66699 4.99998 6.66699H6.66665" stroke="#4B465C" stroke-opacity="0.75" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>