<script setup>

</script>

<template>
  <svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="paint">
      <path id="Vector" d="M17.4163 5.5H18.333C18.8192 5.5 19.2856 5.69315 19.6294 6.03697C19.9732 6.38079 20.1663 6.8471 20.1663 7.33333C20.1663 8.54891 19.6835 9.7147 18.8239 10.5742C17.9644 11.4338 16.7986 11.9167 15.583 11.9167H10.9997V13.75M4.58301 4.58333C4.58301 4.0971 4.77616 3.63079 5.11998 3.28697C5.4638 2.94315 5.93011 2.75 6.41634 2.75H15.583C16.0692 2.75 16.5356 2.94315 16.8794 3.28697C17.2232 3.63079 17.4163 4.0971 17.4163 4.58333V6.41667C17.4163 6.9029 17.2232 7.36921 16.8794 7.71303C16.5356 8.05685 16.0692 8.25 15.583 8.25H6.41634C5.93011 8.25 5.4638 8.05685 5.11998 7.71303C4.77616 7.36921 4.58301 6.9029 4.58301 6.41667V4.58333ZM9.16634 14.6667C9.16634 14.4236 9.26292 14.1904 9.43483 14.0185C9.60673 13.8466 9.83989 13.75 10.083 13.75H11.9163C12.1595 13.75 12.3926 13.8466 12.5645 14.0185C12.7364 14.1904 12.833 14.4236 12.833 14.6667V18.3333C12.833 18.5764 12.7364 18.8096 12.5645 18.9815C12.3926 19.1534 12.1595 19.25 11.9163 19.25H10.083C9.83989 19.25 9.60673 19.1534 9.43483 18.9815C9.26292 18.8096 9.16634 18.5764 9.16634 18.3333V14.6667Z" stroke="#30B271" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<style scoped>

</style>