<script setup>

</script>

<template>
  <svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="share-3">
      <g id="Vector">
        <path d="M11.9168 3.66675V7.33341C5.88976 8.27575 3.64851 13.5557 2.75017 18.3334C2.71626 18.5222 7.68551 12.8682 11.9168 12.8334V16.5001L19.2502 10.0834L11.9168 3.66675Z" stroke="#4B465C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.9168 3.66675V7.33341C5.88976 8.27575 3.64851 13.5557 2.75017 18.3334C2.71626 18.5222 7.68551 12.8682 11.9168 12.8334V16.5001L19.2502 10.0834L11.9168 3.66675Z" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>